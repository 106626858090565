import { waitCheck } from "../utils";

export function AvoInspector({
  apiKey,
  env = "dev",
  version = "v1",
  appName = "mileiq.com",
}) {
  !(function () {
    var t = (window.inspector = window.inspector || []);
    (t.methods = [
      "trackSchemaFromEvent",
      "trackSchema",
      "setBatchSize",
      "setBatchFlushSeconds",
    ]),
      (t.factory = function (e) {
        return function () {
          var r = Array.prototype.slice.call(arguments);
          return r.unshift(e), t.push(r), t;
        };
      });
    for (var e = 0; e < t.methods.length; e++) {
      var r = t.methods[e];
      t[r] = t.factory(r);
    }
    (t.load = function () {
      var t = document.createElement("script");
      (t.type = "text/javascript"),
        (t.async = !0),
        (t.src = "https://cdn.avo.app/inspector/inspector-v1.min.js");
      var e = document.getElementsByTagName("script")[0];
      e.parentNode.insertBefore(t, e);
    }),
      (t._scriptVersion = 1);
  })();

  inspector.__API_KEY__ = apiKey;
  inspector.__ENV__ = env;
  inspector.__VERSION__ = version;
  inspector.__APP_NAME__ = appName;

  inspector.load();
}

export async function initAvoInspector({ apiKey, env }) {
  new AvoInspector({
    apiKey,
    env,
  });

  return await waitCheck(10000, () => !Array.isArray(inspector) && inspector);
}
