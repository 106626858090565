import { initAvo } from "./avo";
import { getImpactClickId } from "./impact";
import { getTrackingAttributesFromUrl } from "./utils";

export async function init() {
  if (window.avo) return window.avo;
  window.avo = initAvo();
  return await window.avo;
}

export function identify({ id, email }) {
  avo.identify({
    id,
    email,
  });
}

export function trackWebPageViewed(page, landingPageVariant) {
  try {
    Avo.webPageViewed({
      platform: "Web",
      webPage: page,
      url: window.location.href,
      landingPageVariant,
      impactClickId: getImpactClickId() ?? null,
      ...getTrackingAttributesFromUrl(),
    });
  } catch (e) {
    console.error(e);
  }
}

export function trackExperimentViewed(experiment) {
  Avo.experimentViewed(experiment);
}

export function trackEvent(event, properties) {
  Avo[event](properties);
}
