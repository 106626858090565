import { ENV, ScriptLoader, waitCheck } from "./utils";

class LaunchDarkly extends ScriptLoader {
  client;
  clientSideID;

  constructor(props = {}) {
    const { env = ENV() } = props;
    const {
      srcUrl = "https://miqwebflowscripts.blob.core.windows.net/scripts/vendors/ldclient.min.js",
    } = props;
    super({
      ...props,
      autoInit: false,
      srcUrl,
    });

    this.clientSideID =
      props.clientSideID ?? env === "prod"
        ? "61d5e01efbf7870e5560dc76"
        : "61d5e01efbf7870e5560dc75";
  }

  init = async (cientSideID = this.clientSideID) => {
    if (this.client) return this.client;
    await super.init().catch(console.error);

    // Wait for a reasonable time for LDClient to be available, then fail explicitly
    await waitCheck(50000, () => window.LDClient);

    this.client = window.LDClient.initialize(cientSideID, {
      kind: "user",
      anonymous: true,
    });

    return this.client;
  };
}

const LD = new LaunchDarkly();

export { LD as LaunchDarkly };
