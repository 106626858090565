console.log("[miq-scripts] loaded");

import * as MiQ from "./src";

Object.defineProperty(window, "MiQ", {
  get: () => MiQ,
});

// try to track Web Page Viewed for affiliate landing pages
// those are pages with a pathname starting matching the following format:
// `/lp/<page-variant>`

let lpMatch = window.location.pathname.match(/\/lp\/([\w-]+)/);
if (lpMatch?.length > 1) {
  let lpVariant = lpMatch[1];
  MiQ.Telemetry.init().then(() =>
    MiQ.Telemetry.trackWebPageViewed("Affiliate Landing Page", lpVariant)
  );
}
