export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export function setCookie(name, value, days = 365) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};Domain=.mileiq.com;Path=/`;
}

export function getTrackingAttributesFromUrl() {
  const usp = new URLSearchParams(window.location.search);
  const _getParam = (param, defaultVal = "") => {
    return (usp.get(param) || defaultVal).toLowerCase();
  };
  const utmSource = _getParam("utm_source");
  const utmCampaign = _getParam("utm_campaign");
  const utmContent = _getParam("utm_content");
  const utmId = _getParam("utm_id");
  const utmMedium = _getParam("utm_medium");
  const utmTerm = _getParam("utm_term");
  const metaAudience = _getParam("meta_audience");
  const gaHsaAcc = _getParam("hsa_acc");
  const gaHsaNet = _getParam("hsa_net");
  const gaHsaVersion = _getParam("hsa_ver");
  const gaHsaCampaign = _getParam("hsa_cam");
  const gaHsaGroup = _getParam("hsa_grp");
  const gaHsaAd = _getParam("hsa_ad");
  const gaHsaSrc = _getParam("hsa_src");
  const gaHsaTarget = _getParam("hsa_tgt");
  const gaHsaKeyword = _getParam("hsa_kw");
  const gaHsaMatchtype = _getParam("hsa_mt");

  return {
    utmSource,
    utmCampaign,
    utmContent,
    utmId,
    utmMedium,
    utmTerm,
    metaAudience,
    gaHsaAcc,
    gaHsaNet,
    gaHsaVersion,
    gaHsaCampaign,
    gaHsaGroup,
    gaHsaAd,
    gaHsaSrc,
    gaHsaTarget,
    gaHsaKeyword,
    gaHsaMatchtype,
  };
}

export function ENV() {
  return !(
    location.hostname.includes("dash-local.mileiq.com") ||
    location.hostname.includes("localhost") ||
    location.hostname.includes("127.0.0.1")
  ) && location.hostname.includes("mileiq.com")
    ? "prod"
    : "dev";
}

export const waitCheck = (timeout = 10000, callback) =>
  Promise.race([
    new Promise((resolve) => {
      const check = () => {
        const result = callback();
        if (result) {
          resolve(result);
        } else {
          setTimeout(check, 50);
        }
      };
      check();
    }),
    new Promise((resolve) => setTimeout(() => resolve(false), timeout)),
  ]);

export class ScriptLoader {
  constructor({ srcUrl, autoInit = true, async = true }) {
    if (!srcUrl) return;
    this.script = document.createElement("script");
    this.script.src = srcUrl;
    this.script.async = async;

    if (autoInit) {
      this.init();
    }
  }

  async _load() {
    document.head.appendChild(this.script);
    return new Promise((resolve, reject) => {
      this.script.onload = resolve;
      this.script.onerror = reject;
    });
  }

  async init() {
    return this._load().catch(console.error);
  }
}
