import { getCookie, setCookie } from "./utils";

const IMPACT_CLICK_ID_COOKIE_NAME = "impact_clickId";
const IMPACT_ANON_COOKIE_NAME = "impact_anon_id";
const IMPACT_CLICK_ID_URL_PARAM = "irclickid";

async function trackImpactPageLoad() {
  const clickId = getImpactClickId();

  if (!clickId) {
    return;
  }

  const payload = {
    CampaignId: 27542,
    EventDate: new Date().toISOString(),
    PageUrl: window.location.href,
    ClickId: clickId,
    CustomerId: getImpactAnonId(),
  };

  try {
    const res = await fetch("https://trkapi.impact.com/PageLoad", {
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(payload),
      method: "POST",
    });
    const data = await res.json();
    if (res.status == 200) {
      setCookie(IMPACT_CLICK_ID_COOKIE_NAME, data.clickId);
    } else {
      reportImpactIntegraionError(JSON.stringify(data));
    }
  } catch (e) {
    reportImpactIntegraionError(e.message);
  }
}

function reportImpactIntegraionError(msg) {
  const err = new ImpactIntegrationError(msg);
  //   report(err); // TODO: send to sentry
  console.error(err);
}

function getImpactClickId() {
  return getImpactClickIdFromUrl() || getImpactClickIdFromCookie();
}

function getImpactClickIdFromUrl() {
  const qp = new URLSearchParams(window.location.search);
  return qp.get(IMPACT_CLICK_ID_URL_PARAM);
}

function getImpactClickIdFromCookie() {
  return getCookie(IMPACT_CLICK_ID_COOKIE_NAME) ?? null;
}

function getImpactAnonId() {
  let anonId = getCookie(IMPACT_ANON_COOKIE_NAME);
  if (!anonId) {
    anonId = `anon_${crypto.randomUUID()}`;
    setCookie(IMPACT_ANON_COOKIE_NAME, anonId);
  }
  return anonId;
}

class ImpactIntegrationError extends Error {
  constructor(message) {
    super(message);
    this.name = "ImpactIntegrationError";
    this.errorClass = "Impact Integration Error";
  }
}

trackImpactPageLoad();

export { getImpactClickId };
