import { ENV } from "../utils";
import { Mixpanel } from "./destinations/mixpanel";
import { RudderStack } from "./destinations/rudderstack";
import { initAvoInspector } from "./inspector";

const defaultENV = ENV();

// Initialize Mixpanel and RudderStack once
Mixpanel();
RudderStack();

export async function initAvo(options = {}) {
  const { INSPECTOR_API_KEY = "w7zsGtprtR9xMJzHn29I", ENV = defaultENV } =
    options;

  const mixpanelInterface = {
    make: (env, key) => mixpanel.init(key),
    logEvent: (name, props) => mixpanel.track(name, props),
    setUserProperties: (id, props) => mixpanel.people.set(props),
    identify: (id) => mixpanel.identify(id),
    unidentify: () => mixpanel.reset(),
    page: (name, props) => {
      mixpanel.track("Page Viewed", { ...props, pageName: name });
    },
  };

  const rudderstackInterface = {
    make: (env, key) => {
      rudderanalytics.load(
        key,
        "https://mileiqjoeplpv.dataplane.rudderstack.com",
        {
          logLevel: "DEBUG",
          storage: { encryption: { version: "legacy" } },
        }
      );
      rudderanalytics.page();
    },
    logEvent: (name, props) => rudderanalytics.track(name, props),
    setUserProperties: () => {},
    identify: (id) => rudderanalytics.identify(id),
    unidentify: () => rudderanalytics.reset(),
  };

  function identify({ id, email } = {}) {
    if (!id) return;
    mixpanelInterface.identify(id);
    mixpanelInterface.setUserProperties(id, { email });
    rudderstackInterface.identify(id);
  }

  return initAvoInspector({
    apiKey: INSPECTOR_API_KEY,
    env: ENV,
  }).then((inspector) => {
    Avo.initAvo(
      {
        env: ENV,
        inspector,
        webDebugger: 1,
        webDebuggerOptions: {
          position: Avo.WebDebuggerPosition.BottomLeft({
            bottom: 80,
            left: 20,
          }),
        },
      },
      null,
      {},
      mixpanelInterface,
      rudderstackInterface
    );
    return {
      Avo,
      identify,
    };
  });
}
