import { LaunchDarkly } from "./launchdarkly";
import * as Telemetry from "./telemetry";

export async function init() {
  try {
    await Promise.all([LaunchDarkly.init(), Telemetry.init()]).catch(
      (error) => {
        throw error;
      }
    );

    return {
      Experiments: new Experiments({
        document: window.document,
        experimentViewed: Telemetry.trackExperimentViewed,
        launchDarkly: LaunchDarkly,
      }),
    };
  } catch (error) {
    console.error("Error initializing experiments", error);
  }
}

class Experiments {
  experiments = new Map();
  launchDarkly = null;
  variationDetail = null;
  document = null;
  experimentViewed = null;
  styleTag = null;
  defaultStyles = `/* This should be placed in the page styles also to avoid FOUC, this is a fallback just in case */
\nhtml:not([data-flags-ready]) [data-flag-variant]:not([data-flag-loading-state]) { display: none; }\n\n`;

  constructor({ document, experimentViewed, launchDarkly }) {
    this.launchDarkly = launchDarkly;
    this.variationDetail = launchDarkly.client.variationDetail;
    this.document = document;
    this.experimentViewed = experimentViewed;
    this.appendStyle();
  }

  appendStyle = () => {
    const id = "miqexperiments-variants-css";
    const style =
      this.document.getElementById(id) ?? document.createElement("style");
    style.textContent = this.defaultStyles;
    style.id = id;
    this.styleTag = style;
    document.head.appendChild(style);
  };

  checkFlag = (flagKey) => this.variationDetail(flagKey, null);

  addExperiment = ({ flagKey, experimentId, experimentName }) =>
    this.experiments.set(flagKey, { flagKey, experimentId, experimentName });

  logExperimentViewed = ({ flagKey, variant }) => {
    const inExperiment = variant?.reason?.inExperiment;
    const experiment = this.experiments.get(flagKey);
    this.experiments.set(flagKey, {
      ...experiment,
      inExperiment,
      variant,
    });
    if (inExperiment) {
      this.experimentViewed({
        platform: "Web",
        experimentId: experiment.experimentId,
        experimentName: experiment.experimentName,
        variationId: variant.variationIndex,
        variationName: variant.value,
      });
    }
  };

  renderExperiments = (skip) => {
    let cssRules = `${this.defaultStyles}`;
    cssRules += `[data-flag-loading-state] { display: none!important; }\n\n`;
    const defaultVariant = {
      value: "control",
      reason: { inExperiment: false },
    };

    this.experiments.forEach(({ flagKey }) => {
      try {
        const variant = skip
          ? defaultVariant
          : this.checkFlag(flagKey) ?? defaultVariant;

        cssRules += `[data-flag-key="${flagKey}"]:not([data-flag-variant="${variant.value}"]),\n`;

        if (!skip) {
          this.logExperimentViewed({ flagKey, variant });
        }
      } catch (e) {
        console.error(e);
      }
    });

    // Remove trailing comma from last line
    cssRules = cssRules.replace(/,\n$/, "\n");
    cssRules += `{ display: none!important; }`;
    this.styleTag.textContent = cssRules;
    this.document.documentElement.setAttribute("data-flags-ready", "true");
  };

  runExperiments = (experiments) => {
    experiments?.forEach((experiment) => {
      this.addExperiment(experiment);
    });
    if (experiments?.length) {
      if (this.launchDarkly?.client?.getContext?.()) {
        this.renderExperiments();
      }
      const onReady = () => {
        this.renderExperiments();
        this.launchDarkly?.client?.off("ready", onReady);
      };
      this.launchDarkly?.client?.on("ready", onReady);
      this.launchDarkly?.client?.on("change", this.renderExperiments);
      const onFailed = () => {
        this.renderExperiments(true);
        this.launchDarkly?.client?.off("failed", onFailed);
      };
      this.launchDarkly?.client?.on("failed", onFailed);
    }
  };
}
